<template>
  <div>
    <b-sidebar
      id="sidebar-footer"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      :visible="sidebarInfoActive"
      @change="(val) => $emit('update:sidebar-info-active', val)"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">USUÁRIO</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="px-1 mt-2" v-if="Object.keys(user).length > 0">
          <b-row>
            <b-col md="9 mx-auto">
              <b-media class="text-center">
                <div class="mb-1">
                  <b-avatar
                    :src="require('@/assets/images/avatars/12-small.png')"
                    size="64"
                    v-b-tooltip.hover.v-primary
                    v-b-tooltip.hover.right="'Abrir histórico'"
                  />
                </div>
                <h6>{{ user.name }}</h6>
                <p style="margin-top:-5px" class="font-italic">
                  <small>{{ user.email }}</small>
                </p>
              </b-media>
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="user.telephone">
            <b-col>
              <h5 class="d-inline">
                Telefone:
              </h5>
              {{ user.telephone | phoneNumber }}
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="user.birthDate">
            <b-col>
              <h5 class="d-inline">
                Aniversário:
              </h5>
              {{ user.birthDate | moment("DD [de] MMM [de] YYYY") }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <h5 class="d-inline">
                Status:
              </h5>
              {{ user.deletedAt === null ? "Ativado" : "Desativado" }}
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="user.description">
            <b-col>
              <h5 class="d-inline">
                Descrição:
              </h5>
              {{ user.description | ucFirst }}
            </b-col>
          </b-row>
        </div>
      </template>
      <template #footer>
        <sidebar-footer
          :user="user"
          @hide="() => $emit('update:sidebar-info-active', false)"
          @open-sidebar-form="() => $emit('open-sidebar-form', user)"
          @disable-enable="disableEnable"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BSidebar,
  BAvatar,
  BMedia,
  VBTooltip,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

import SidebarFooter from "./SidebarFooter.vue";

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BAvatar,
    BMedia,
    SidebarFooter,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  props: {
    sidebarInfoActive: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async disableEnable() {
      try {
        if (!Object.keys(this.user).length) {
          return;
        }

        const enableDisable =
          this.user.deletedAt === null
            ? "Tem certeza que deseja desativar este usuário?"
            : "Tem certeza que deseja ativar este usuário?";

        const action = await this.$bvModal.msgBoxConfirm(enableDisable, {
          title: "Tem certeza desta ação?",
          size: "sm",
          okVariant: "primary",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        });

        if (action) {
          const msg =
            this.user.deletedAt === null
              ? "Usuário do sistema desativado com sucesso"
              : "Usuário do sistema ativado com sucesso";

          await store.dispatch("user/disableEnable", this.user);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: msg,
              icon: "UserIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              err.response != undefined
                ? err.response.data.message
                : `Erro ao desativar/ativar o usuário`,
            icon: "UserIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>
