<template>
  <div>
    <b-sidebar
      id="sidebar-footer"
      sidebar-class="sidebar-lg"
      aria-label="Sidebar with custom footer"
      bg-variant="white"
      :visible="sidebarFormActive"
      @change="(val) => $emit('update:sidebar-form-active', val)"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0" v-if="user.id !== undefined">EDITAR USUÁRIO</h5>
          <h5 v-else class="mb-0">ADICIONAR USUÁRIO</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="px-1 mt-2">
          <b-form>
            <b-row class="mt-1">
              <b-col sm="12">
                <b-form-group label="Nome" label-for="name">
                  <b-form-input
                    :id="'name'"
                    v-model="userLocal.name"
                    type="text"
                    placeholder="Nome"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Telefone" label-for="telephone">
                  <b-form-input
                    :id="'telephone'"
                    v-model="userLocal.telephone"
                    type="text"
                    maxlength="15"
                    placeholder="Telefone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="E-mail" label-for="email">
                  <b-form-input
                    :id="'email'"
                    v-model="userLocal.email"
                    type="text"
                    placeholder="E-mail"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Data de aniversário" label-for="birthDate">
                  <flat-pickr
                    :id="'birthDate'"
                    class="form-control"
                    :config="dateConfig"
                    v-model="userLocal.birthDate"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Senha" label-for="password">
                  <b-form-input
                    :id="'password'"
                    v-model="userLocal.password"
                    type="password"
                    :placeholder="
                      user.id !== undefined
                        ? 'Alterar senha'
                        : 'Informe uma senha'
                    "
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Descrição" label-for="description">
                  <b-form-textarea
                    :id="'description'"
                    v-model="userLocal.description"
                    type="text"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </template>
      <template #footer>
        <sidebar-footer
          :user="user"
          @hide="() => $emit('update:sidebar-form-active', false)"
          @form-submit="submitForm"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCol,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  VBTooltip,
} from "bootstrap-vue";

import SidebarFooter from "./SidebarFooter.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    SidebarFooter,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  props: {
    sidebarFormActive: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    const user = {
      name: "",
      email: "",
      telephone: "",
      birthDate: "",
      description: "",
    };

    return {
      userLocal: Object.assign(user, this.user),
      statusOptions: [
        { label: "Ativo", value: "ativo" },
        { label: "Expirado", value: "expirado" },
      ],
      dateConfig: {
        altInput: true,
        altFormat: "d \\de M\\. \\de Y",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        allowInput: false,
      },
    };
  },

  watch: {
    sidebarFormActive() {
      this.userLocal = Object.assign({}, this.user);
    },
  },

  methods: {
    async submitForm() {
      try {
        const data = Object.assign({}, this.userLocal);
        data.telephone = this.userLocal.telephone.replace(/[^\d]/g, "");
        let message = `Usuário adicionado com sucesso`;

        if (Object.keys(this.user).length) {
          await store.dispatch("user/update", data);
          message = `Usuário editado com sucesso`;
        } else {
          await store.dispatch("user/add", data);
          await store.dispatch("user/fetch");
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: "UserIcon",
            variant: "success",
          },
        });
        this.$emit("update:sidebar-form-active", false);
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              err.response != undefined
                ? err.response.data.message
                : `Erro ao editar o usere`,
            icon: "UserIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
