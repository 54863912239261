var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('info-sidebar',{attrs:{"sidebarInfoActive":_vm.sidebarInfoActive,"user":_vm.user},on:{"update:sidebarInfoActive":function($event){_vm.sidebarInfoActive=$event},"update:sidebar-info-active":function($event){_vm.sidebarInfoActive=$event},"open-sidebar-form":_vm.openSidebarForm}}),_c('form-sidebar',{attrs:{"sidebarFormActive":_vm.sidebarFormActive,"user":_vm.user},on:{"update:sidebarFormActive":function($event){_vm.sidebarFormActive=$event},"update:sidebar-form-active":function($event){_vm.sidebarFormActive=$event}}}),_c('div',{staticClass:"custom-search"},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":function () { return (_vm.sidebarFormActive = true); }}},[_vm._v(" ADICIONAR ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Pesquisar:")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},on:{"input":_vm.advanceSearch}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Status:")]),_c('b-form-select',{attrs:{"options":_vm.statusOptions},model:{value:(_vm.statusSelected),callback:function ($$v) {_vm.statusSelected=$$v},expression:"statusSelected"}})],1)],1)],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.users,"rtl":_vm.direction,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm,
    },"select-options":{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"pagination-options":{
      enabled: true,
      perPage: _vm.pageLength,
    },"theme":"my-theme"},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"src":props.row.avatar}}),_c('span',[_vm._v(_vm._s(props.row.name))])],1):(
          props.column.field === 'birthDate' ||
            props.column.field === 'dueDate'
        )?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(props.formattedRow[props.column.field],"DD/MM/YYYY"))+" ")]):(props.column.field === 'deletedAt')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field] ? "Desativado" : "Ativo")+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Exibindo 1 até ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3', '5', '10']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" de "+_vm._s(props.total)+" registros ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }